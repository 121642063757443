import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import LazyLoad from 'vanilla-lazyload';
import './App.css';

import Home from '../../Components/Home/Home';
import About from '../../Components/About/About';
import WorkContainer from '../WorkContainer/WorkContainer';
import Navigation from '../../Components/Navigation/Navigation';

// const cheatData = require('../../data.json');

function App() {
  const [page, setPage] = useState('home');
  const [data, setData] = useState({
    portraits: [],
    fashion: [],
    other: [],
    slug: '',
    homeImg: '',
    sections: []
  });
  
  function setHomeImg(work) {
    const slug = work.slug;
    const unsortedSelection = [...work.sections.portraits, ...work.sections.fashion, ...work.sections.other];
    const selected = unsortedSelection[Math.floor(Math.random() * unsortedSelection.length)];
    
    const findSelectedInfo = (s) => {
      if (work.sections.portraits.includes(s)) return 'portraits';
      if (work.sections.fashion.includes(s)) return 'fashion';
      if (work.sections.other.includes(s)) return 'other';
    };

    return `${slug}/${findSelectedInfo(selected)}/${selected}`;
  };

  function fetchData() {
    fetch('https://brie-data.s3.amazonaws.com/data.json', {
      'method': 'GET'
    })
    .then(response => response.json())
    .then(data => {
      setData({
        portraits: [...data.photography.sections.portraits],
        fashion: [...data.photography.sections.fashion],
        other: [...data.photography.sections.other],
        slug: data.photography.slug,
        homeImg: setHomeImg(data.photography),
        sections: Object.keys(data.photography.sections)
      });
    })
    .catch(err => {
      console.log('fetch error: ', err);
    });
  };

  // function setMyData() {
  //   setData({
  //     portraits: [...cheatData.photography.sections.portraits],
  //     fashion: [...cheatData.photography.sections.fashion],
  //     other: [...cheatData.photography.sections.other],
  //     slug: cheatData.photography.slug,
  //     homeImg: setHomeImg(cheatData.photography),
  //     sections: Object.keys(cheatData.photography.sections)
  //   });
  // };

  new LazyLoad({
    threshold: 500
  });

  useEffect(() => {
    // setMyData();
    fetchData();
  }, []);

  return (
    <div className="App">
      <Router>
        <Navigation location={page} changePage={setPage} sections={data.sections} />
        
        <Switch>
          <Route path='/portraits' render={() => <WorkContainer changePage={setPage} slug={data.slug} imageList={data.portraits} page={'portraits'} />} />
          <Route path='/fashion' render={() => <WorkContainer changePage={setPage} slug={data.slug} imageList={data.fashion} page={'fashion'} />} />
          <Route path='/other' render={() => <WorkContainer changePage={setPage} slug={data.slug} imageList={data.other} page={'other'} />} />
          <Route path='/about' render={() => <About changePage={setPage} />} />     
          <Route path='/' render={() => <Home bgImg={data.homeImg} />} />
        </Switch>
      </Router>
    </div>
  );
}

export default App;
