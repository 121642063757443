import React, { useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './Navigation.css';

import emailIcon from '../../Assets/Images/mail-icon-blue.svg';
import githubIcon from '../../Assets/Images/github-icon.svg';
import linkedInIcon from '../../Assets/Images/linkedin-icon-blue.svg';
import instaIcon from '../../Assets/Images/insta-icon-blue.svg';
import hamburgerIcon from '../../Assets/Images/hamburger.svg';
import bIcon from '../../Assets/Images/b-white.svg';
import xIcon from '../../Assets/Images/x-white.svg';

export default function Navigation(props) {
  const [open, setOpen] = useState(false);
  const boiga = useRef();

  function openClose() {
    setOpen(!open);
  };

  function changePage(p) {
    props.changePage(p);
  };

  useEffect(() => {
    boiga.current.classList.add('on');

    window.setTimeout(() => {
      boiga.current.classList.remove('on');
    }, 1500);
  }, []);

  return (
    <div className={`navigation-container ${props.location} ${open ? 'open' : ''}`}>

      <div className='links' onClick={openClose}>
        
        <Link to='/' onClick={() => changePage('home')} className={`${props.location === 'home' ? 'active' : ''}`}>
          home
        </Link>

        {props.sections.map(s => {
          return (
            <Link key={s} to={`/${s}`} onClick={() => changePage(s)} className={`${props.location === s ? 'active' : ''}`}>
              {s}
            </Link>
          );
        })}
        
        <Link to='/about' onClick={() => changePage('about')} className={`${props.location === 'about' ? 'active' : ''}`}>
          about
        </Link>

        <div className='social-icons'>
          <a href='mailto:hello@brie.work' target='_blank' rel='noopener noreferrer' onClick={openClose}>
            <img src={emailIcon} alt='Email' />
          </a>

          <a href='https://www.instagram.com/brie.photo/' target='_blank' rel='noopener noreferrer' onClick={openClose}>
            <img src={instaIcon} alt='Insta' />
          </a>
          
          <a href='https://github.com/briecodes' target='_blank' rel='noopener noreferrer' onClick={openClose}>
            <img src={githubIcon} alt='Github' />
          </a>

          <a href='https://www.linkedin.com/in/briesaez/' target='_blank' rel='noopener noreferrer' onClick={openClose}>
            <img src={linkedInIcon} alt='LinkedIn' />
          </a>
        </div>
      </div>

      <div className='navbtn mobile' onClick={props.projectOpen ? props.closeProject : openClose}>
        <img src={hamburgerIcon} alt='Hamburger Menu' className='h-icon' ref={boiga} />
        <img src={bIcon} alt='B Icon' className={`b-icon ${props.projectOpen ? 'off' : '' }`} />
        <img src={xIcon} alt='Close' className={`x-icon ${props.projectOpen ? 'on' : '' }`} />
      </div>
      
      <Link to='/' onClick={() => changePage('home')} className='desktop'>
        <div className='navbtn'>
          <img src={bIcon} alt='B Icon' className={`b-icon ${props.projectOpen ? 'off' : '' }`} />
          <img src={xIcon} alt='Close' className={`x-icon ${props.projectOpen ? 'on' : '' }`} />
        </div>
      </Link>
    </div>
  );
};