import React, {useEffect} from 'react';
import './About.css';

import emailIcon from '../../Assets/Images/mail-icon-blue.svg';

export default function About(props) {

  useEffect(() => {
    window.scrollTo(0, 0);
    props.changePage('about');
  }, [props]);

  return (
    <div className='about-container'>
      <h1>about</h1>
      <p>
        I’m a Brooklyn-based photographer (on top of a Full-Stack software engineer, Senior Art Director, and Creative Lead) focusing on in-studio black/white portraiture.
      </p>
      <p>
        I am currently accepting commissions on a sliding scale; you can contact me by clicking:&nbsp;&nbsp;
        <a href='mailto:hello@brie.work' target='_blank' rel='noopener noreferrer'>
          <img src={emailIcon} alt='Email' width='15' />
        </a>
      </p>
    </div>
  );
};
